@font-face {
  src: url("../fonts/Assistant-VariableFont_wght.ttf");
  font-family: assistant;
}

* {
  font-family: assistant !important;
}

:root {
  --green-color: #73c92b;
  --gray-dark: #a6a2a2;
  --gray-light: #d9d9d9;
  --blue-dark: #004598;
}

.hidden {
  visibility: hidden;
}

.header-background-img {
  position: relative;
  aspect-ratio: 13/4;
  width: 100%;
  background-color: var(--gray-dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.group-form-page-title {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  transform: translate(-50%, -50%);
  font-size: 80px;
  font-weight: 800;
  text-align: center;
}

.group-form-title-bar-container {
  position: relative;
  margin-bottom: 90px;
}

.group-form-info-box-container {
  width: 65%;
  max-width: 1100px;
  background-color: var(--blue-dark);
  color: white;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -40px;
  height: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 15px 0;
}

.group-form-info-box {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.group-form-info-box + .group-form-info-box {
  border-right: 3px solid;
  border-color: white;
}

.group-form-info-box-header {
  width: fit-content;
  margin: auto;
  font-size: 20px;
  margin: 0px;
  color: white;
}

.group-form-info-box-content {
  margin: 0px;
}

/* Form */

.group-form-form {
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  width: 70%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-right: 8%;
  padding-left: 8%;
  margin-bottom: 50px;
}

.group-form-title {
  font-style: normal;
  color: #004598;
  font-weight: 800;
  font-size: 28px;
  text-align: center;
  margin: 30px;
}

.group-form-inputs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.group-form-inputs-container-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.group-form-input-label {
  position: relative;
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}

.group-form-radio-label {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}

.group-form-input {
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  outline: none;
  width: 100%;
  flex-basis: 50%;
  border: 1px solid var(--gray-dark);
  border-radius: 4px;
  font-size: 20px;
  margin-bottom: 5px;
}

/* Overriding MUI here but it's fitting the main website theme anyway */
/* all MUI Select elements are styled by this */
.group-form-input-mui input,
.MuiSelect-select {
  background-color: white !important;
  font-size: 20px !important;
}

.group-form-input-mui input::placeholder {
  opacity: 0.65;
}

.text-english {
  text-align: left;
  direction: ltr;
}

.text-format-error {
  color: red;
  position: absolute;
  bottom: -30px;
}

.group-form-file-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  background: white;
  border: 5px solid var(--gray-light);
  border-radius: 12px;
  padding: 6px;
  padding-right: 15px;
  padding-left: 10px;
  margin-top: 20px !important;
  margin-bottom: 21px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.group-form-file-input-text {
  flex-grow: 1;
  text-align: right;
  margin-right: 15px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.group-form-file-input-text-label {
  margin: 0;
  font-size: 20px;
}

.group-form-file-input-text-falsy {
  margin: 0;
  color: var(--gray-dark);
  font-size: 15px;
  margin-top: -6px;
}

.file-input-error {
  color: red;
  font-weight: bold;
}

.group-form-file-input-text-success {
  margin: 0;
  color: var(--green-color);
  font-weight: bold;
  font-size: 15px;
  margin-top: -6px;
}

.submit-button,
.group-form-file-input-button {
  background: var(--green-color);
  color: white;
  text-align: center;
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}

.submit-button {
  margin: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

.group-form-sub-heading {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  color: var(--green-color);
}

.group-form-more-info-buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  margin: auto;
  margin-bottom: 40px;
}

.group-form-more-info-buttons {
  background-color: var(--blue-dark);
  color: white;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  padding: 5px;
  width: 100px;
}

.selected {
  background-color: var(--green-color);
}

.image-info {
  display: flex;
  margin-bottom: 50px;
}

.image-info-text {
  margin-left: 10%;
  text-align: justify;
  font-size: 20px;
}

.group-form-img {
  max-width: 300px;
  margin: auto;
}

.webcam {
  display: flex;
  flex-direction: column;
  width: 45vw;
}

.webcam-btn-container {
  display: flex;
}

.webcam-btn {
  width: 100%;
  font-size: 25px;
  color: white;
  font-weight: bold;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: var(--green-color);
}

.bg-blue {
  background-color: var(--blue-dark);
}

.group-form-wrong-image {
  position: relative;
  /* //hide overflow of pseudo elements */
  overflow: hidden;
}

.group-form-wrong-image:after {
  position: absolute;
  content: "X";
  font-size: 200px;
  color: red;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  margin: auto;
}

.group-form-wrong-image-filter {
  width: 200px;
  filter: saturate(40%);
}

.group-form-picture-of-passport img {
  width: 300px;
}

.group-form-picture-of-person {
  max-width: 200px;
}

@media only screen and (max-width: 1300px) {
  .group-form-info-box-container {
    width: 70%;
  }
  .group-form-form {
    width: 80%;
    padding-right: 7%;
    padding-left: 7%;
  }
}

@media only screen and (max-width: 1100px) {
  .group-form-title-bar-container {
    position: relative;
    margin-bottom: 200px;
  }

  .group-form-info-box-container {
    width: 75%;
    bottom: -150px;
  }
  .group-form-form {
    width: 90%;
    padding-right: 5%;
    padding-left: 5%;
  }
  .group-form-file-input-container,
  .group-form-input-label {
    width: 47%;
  }
}

@media only screen and (max-width: 900px) {
  .group-form-page-title {
    font-size: 60px;
  }
  .group-form-info-box-container {
    width: 80%;
  }
  .group-form-form {
    width: 95%;
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media only screen and (max-width: 800px) {
  .group-form-page-title {
    font-size: 45px;
  }
  .group-form-info-box-container {
    width: 85%;
  }
  .group-form-inputs-container {
    flex-direction: column;
  }

  .group-form-sub-heading {
    margin-top: 0;
  }

  .group-form-file-input-container {
    width: 90%;
    margin: auto;
  }

  .group-form-input-label {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }

  .image-info {
    flex-direction: column-reverse;
  }
  .image-info-text {
    margin: auto;
    margin-top: 20px;
    width: 90%;
  }
  .group-form-more-info-buttons-container {
    width: 90%;
  }
  .webcam {
    width: 80vw;
  }
  .webcam-btn {
    font-size: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .group-form-page-title {
    font-size: 35px;
  }
  .group-form-info-box-container {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    padding: 1rem;
    bottom: -170px;
    align-items: stretch;
    padding: 0.7rem;
  }

  .group-form-info-box {
    width: 50%;
  }

  /* The following styles cancel the separating borders in the header and add relevant ones for the smaller layout */
  .group-form-info-box + .group-form-info-box {
    border: 0;
  }

  .group-form-info-box:nth-child(odd) {
    border-left: 2px solid white;
  }

  .group-form-info-box:first-child,
  .group-form-info-box:nth-child(2) {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
  }

  .group-form-info-box:nth-child(3),
  .group-form-info-box:last-child {
    padding-top: 5px;
  }

  .group-form-info-box-content {
    font-size: small;
    margin: 5px;
  }

  .group-form-file-input-container,
  .group-form-input-label {
    width: 100%;
  }

  .image-info-text {
    font-size: 15px;
  }

  /* for making the example photos fit in the modal popup on mobile */
  .group-form-columns-on-mobile {
    flex-flow: column nowrap;
    align-items: center;
  }
}

@media only screen and (max-width: 300px) {
  .group-form-page-title {
    font-size: 30px;
  }
  .group-form-info-box-container {
    bottom: -185px;
  }

  .group-form-picture-of-passport img {
    width: 250px;
  }
}

@media (hover: hover) {
  .submit-button:hover,
  .group-form-file-input-button:hover {
    background: var(--gray-dark);
  }

  .group-form-more-info-buttons:hover {
    background-color: var(--gray-dark);
  }

  .webcam-btn:hover {
    background-color: var(--gray-dark);
  }
}
