.footer-b2c {
  display: flex;
}

.footer-top-links-b2c {
  display: flex;
  color: white;
}

.footer-top-links-b2c ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  font-weight: bold;
  font-size: 22px;
  margin: 40px auto;
  padding: 0;
}

.footer-top-links-b2c li a {
  color: white;
}

.footer-top {
  direction: ltr;
}

.footer-top-links {
  display: inline-block;
  text-align: right;
  width: 60%;
  padding-right: 60px;
  margin-top: 30px;
}

.footer-top-links ul {
  list-style-type: none;
}

.footer-top-links li {
  display: inline-block;
  margin: 0 7px;
}

.footer-top-links li a {
  color: white;
}

.white-line-sep {
  margin: 25px 0;
  background-color: white;
  height: 1px;
}

.footer-middle-b2c {
  text-align: right;
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 22px;
}

.footer-middle-b2c ul {
  list-style-type: none;
  padding-right: 0;
}

.footer-middle-b2c li {
  font-size: 15px;
}

.footer-text-b2c {
  font-size: 16px;
  margin-right: 40px;
}

.footer-text-middle-b2c ul {
  width: 150px;
}

.footer-middle {
  text-align: right;
  display: flex;
  justify-content: space-around;
}

.footer-middle > * {
  flex-basis: 33%;
}

.footer-middle ul {
  list-style-type: none;
  padding-right: 0;
}

.footer-middle li {
  font-size: 15px;
}

@media only screen and (max-width: 1100px) {
  .footer-top-links {
    margin-top: 20px;
  }

  .footer-middle-b2c {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .footer-text-b2c {
    margin-right: 0;
  }
}

@media only screen and (max-width: 630px) {
  .top-logo {
    display: block;
    float: none !important;
    text-align: center;
    margin: 0 auto;
    width: unset;
  }

  .footer-top {
    text-align: center;
  }

  .footer-top-links {
    padding: 0;
    text-align: center;
    margin: 30px auto;
    width: 100%;
  }

  .footer-top-links ul {
    padding: 0;
  }

  .footer-middle {
    flex-direction: column;
    text-align: center;
  }

  .footer-middle h5 {
    text-align: center;
  }

  .footer-middle-b2c {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .footer-text-b2c {
    margin-right: 0;
  }
}
