:root {
  --gray-lighter: #f4f4f4;
  --gray-middle: #999999;
}

.group-info-table th {
  text-align: start;
  font-size: 1.1rem;
  font-weight: bolder !important;
}

.group-info-table th + th {
  border-right: 1px white solid;
}

.group-info-table td {
  font-size: 1rem;
  text-align: start;
}

.group-info-table-head {
  background-color: var(--gray-light);
}

.group-info-sub-table {
  width: 100%;
  margin: 0;
  padding: 0;
}

.group-info-sub-table-head {
  background-color: var(--gray-lighter);
}

.group-info-sub-table td {
  vertical-align: top;
  text-align: start;
  font-size: 1rem;
}

.group-info-table-sub-box {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
}

.group-info-bold-grey {
  font-weight: bold !important;
  color: var(--gray-middle);
}
