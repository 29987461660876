.dubai-banner {
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  height: calc(100vh - 66px);
}

.dubai-big-con {
  position: relative;
  z-index: 1;
  margin-top: 50px;
}

.call-now-or-whatsapp,
.why-us {
  display: inline-block;
  width: 50%;
}

.why-us {
  vertical-align: top;
}

.call-now-or-whatsapp {
  text-align: center;
}

.line-sep {
  margin-top: 50px;
  width: 100%;
  height: 1.5px;
  background: #004598;
  position: relative;
}

.line-sep-txt {
  position: absolute;
  top: -28px;
  right: 50%;
  transform: translate(50%);
  font-size: 33px;
  background-color: white;
  padding-bottom: 2px;
  border-radius: 50%;
  width: 50px;
  border: 1.5px solid #004598;
}

@media only screen and (max-width: 1000px) {
  .dubai-banner {
    height: unset;
  }
}

@media only screen and (max-width: 1000px) {
  .call-now-or-whatsapp {
    display: block;
    width: 90%;
    margin: 0 auto;
  }

  .why-us {
    display: block;
    width: 90%;
    margin: 50px auto;
    text-align: center;
  }
}
