.home-banner-img,
.home-banner-img-sm {
  width: 100%;
}
.how_to_video_frame {
  width: 600px;
  height: 400px;
}
.home-banner-img-sm {
  display: none;
}

.banner-text {
  position: absolute;
  right: 15%;
  top: 7%;
  width: 300px;
}

.align-banner-height {
  margin-top: -85px;
}

.origin-dest-wrapper {
  width: 100%;
  padding: 17px 4% 130px 4%;
  height: 120px;
  text-align: right;
}

.vertical-7 {
  vertical-align: -7px;
  width: 10%;
  margin-right: 10px;
}

.btn-search {
  width: 85px;
  height: 40px;
}

.origin-dest {
  width: 42%;
}

.country-flag {
  width: 75px;
  height: 37.5px;
  border-radius: 5px;
}

.window-con {
  width: 190px;
  padding: 0 10px;
  display: inline-block;
  position: relative;
}

.window-con .window-img {
  width: 100%;
}

.country-name-con {
  position: absolute;
  top: 19px;
  left: 40px;
  width: 110px;
}

.country-name-con h5 {
  display: inline-block;
  vertical-align: -14px;
}

.country-btn-con {
  position: absolute;
  top: 74px;
  left: 52px;
}

.flag-con {
  position: absolute;
  bottom: 40px;
  left: 55px;
  background: white;
  padding: 2.5px;
  border-radius: 4px;
  box-shadow: 2px 2px 3px 0px rgba(50, 50, 50, 0.4);
}

.right-arrow,
.left-arrow {
  position: absolute;
  top: 90px;
  color: #909090;
  cursor: pointer;
}

.right-arrow {
  right: 0;
}

.left-arrow {
  left: 0;
}

.top-logo {
  display: inline-block;
  width: 40%;
}

.autocomplete-white .MuiInputBase-root {
  background: white;
}

.autocomplete-white .MuiInputLabel-shrink {
  background: white;
  padding: 5px;
  border-radius: 5px;
}

.autocomplete-white .MuiAutocomplete-endAdornment {
  left: 0;
  right: unset !important;
}

.autocomplete-white .MuiOutlinedInput-root {
  padding-right: 10px !important;
  padding-left: 65px !important;
}

.step-big-con {
  display: inline-block;
  width: 30%;
  /* margin: 0 1.6%; */
}

.step-big-con .MuiCard-root {
  overflow: initial;
}

.step-big-con .MuiCardContent-root {
  padding-bottom: 5px !important;
}

.abs-light-gray-background {
  position: absolute;
  left: -16px;
  right: -16px;
  top: -16px;
  background-color: #f5f5f5;
  height: 110px;
  z-index: 9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.z-index-10 {
  position: relative;
  z-index: 10;
}

.second-border-radius {
  height: 45px;
  width: 45px;
  border: 2px solid lightgray;
  border-radius: 50%;
  position: absolute;
  left: -3.5px;
  top: -3.5px;
}

.second-border-radius h3 {
  margin-right: 2px;
  margin-top: 4px;
}

.circle-step {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  border: white 1px solid;
  z-index: 11;
}

.step-image {
  height: 70px;
}

.faq-questions {
  display: inline-block;
  width: 70%;
  direction: rtl;
  position: relative;
  z-index: 0;
}

.faq-img {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 30%;
  z-index: 1;
}

.faq-img-inner {
  position: absolute;
  left: -40px;
  top: 10px;
}

.faq-img-b2c {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 30%;
  z-index: 1;
}

.faq-img-inner-b2c {
  position: absolute;
  left: -40px;
  top: 10px;
}

.home-width-90 {
  width: 90%;
}

.align-faq-text {
  margin-top: 8px;
  margin-right: 2px;
}

.inner-accordion {
  text-align: right;
  max-width: 90%;
}

.width40-height-40 {
  width: 40px;
  height: 40px;
}

.who-service-con {
  display: inline-block;
  width: 25%;
}

.increased-btn-size button {
  width: 130px;
  height: 30px;
}

.increased-btn-size-b2c button {
  width: 150px;
  height: 40px;
}

.large-text {
  font-size: 20px;
  font-weight: bold;
}

.whyus-img {
  display: inline-block;
  width: 30%;
  vertical-align: top;
  margin-top: 40px;
}

.whyus-img img {
  width: 150px;
}

.whyus-con {
  display: inline-block;
  width: 70%;
}

.ul-whyus {
  list-style-type: none;
  padding-inline-start: 0;
}

.align-whyus-text {
  margin-top: 10px;
  margin-right: 2px;
  text-align: right;
  margin-top: 10px;
}

.whyus-text-check-img {
  display: inline-block;
  width: 8%;
}

.whyus-text-inner-con {
  display: inline-block;
  width: 89%;
  vertical-align: top;
  /* line-height: 1.2; */
}

.checkicon-reduce {
  width: 30px;
}

.faq-container {
  width: 60%;
  direction: ltr;
}

.visa-type-btn-con {
  box-shadow: 0 0 1rem rgb(0 0 0 / 10%);
  border-radius: 15px;
  width: 200px;
  padding: 1rem;
  display: inline-block;
  margin: 8px;
  cursor: pointer;
}

.visa-type-btn-selected {
  background: #004598;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .window-con:nth-child(5) {
    display: none;
  }

  .whyus-text-check-img {
    width: 11%;
  }
}

@media only screen and (max-width: 1000px) {
  .align-banner-height {
    margin-top: 85px;
  }

  .home-banner-img-sm {
    display: inline;
  }

  .home-banner-img {
    display: none;
  }

  .banner-text {
    position: initial;
    width: initial;
    margin-top: -140px;
  }

  .banner-text h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  .faq-container {
    width: 80%;
    text-align: center;
  }
  .faq-questions {
    display: inline-block;
  }
  .faq-img {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 30%;
  }

  .faq-img-b2c {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 30%;
  }

  .faq-img-inner {
    position: absolute;
    left: -40px;
    top: 10px;
  }

  .faq-img-inner-b2c {
    position: absolute;
    left: -40px;
  }
}

@media only screen and (max-width: 844px) {
  .origin-dest {
    display: block;
    width: 90%;
    margin: 10px auto;
  }

  .vertical-7 {
    display: block;
    text-align: center;
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }

  .origin-dest-wrapper {
    height: 235px;
  }

  .banner-text {
    margin-top: -110px;
  }
}

@media only screen and (max-width: 770px) {
  .inner-accordion {
    max-width: 100%;
  }

  .window-con:nth-child(4) {
    display: none !important;
  }

  .step-big-con {
    display: block;
    width: 90%;
    margin: 55px auto;
  }

  .faq-container {
    width: 100%;
    text-align: center;
  }

  .faq-img {
    display: block;
    position: static;
    width: 100%;
  }

  .faq-img-b2c {
    display: block;
    position: static;
    width: 100%;
  }

  .faq-img-inner {
    position: static;
    margin-left: 40px;
    margin-bottom: 30px;
  }

  .faq-img-inner-b2c {
    position: static;
    margin-left: 40px;
    margin-bottom: 30px;
  }

  .faq-questions {
    display: block;
    width: 100%;
  }

  .who-service-con {
    display: block;
    width: 100%;
    margin-top: 60px;
  }

  .increased-btn-size {
    margin: 30px 0;
    display: block;
  }

  .increased-btn-size button {
    width: 200px;
  }

  .whyus-img {
    display: block;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .whyus-img img {
    width: 200px;
  }
  .whyus-con {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 477px) {
  .how_to_video_frame {
    width: 350px;
    height: 150px;
  }
  .faq-img-inner img {
    width: 100%;
  }

  .faq-img-inner-b2c img {
    width: 100%;
  }

  .whyus-text-check-img {
    width: 15%;
  }

  .whyus-text-inner-con {
    width: 82%;
  }

  .window-con:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .visa-type-btn-con {
    width: 100%;
  }

  .align-banner-height {
    margin-top: 50px;
  }
  .banner-text {
    margin-top: -45px;
  }

  .banner-text h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .banner-text {
    margin-top: -25px;
  }

  .whyus-text-check-img {
    width: 18%;
  }

  .whyus-text-inner-con {
    width: 78%;
  }
}

.circle-step-b2c {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%);
  width: 120px;
  border-radius: 6px;
  display: inline-block;
  border: white 1px solid;
  z-index: 11;
  background-color: #004598;
}

.iconImgFilled-b2c {
  width: 90px;
  height: auto;
  position: absolute;
  top: -20px;
  left: 85px;
}

.step-text-b2c {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.step-image-b2c {
  height: 90px;
}

.imgFAQ-b2c {
  width: 250px;
  height: auto;
}

.service-text {
  font-size: 24px;
  font-weight: bold;
}

.service-icons {
  margin-top: 10px;
  width: 80px;
  height: auto;
}

.title-text-b2c {
  font-size: 38px;
  font-weight: bold;
}

.mt-minus-2 {
  margin-top: -2px;
}

.b2c-step-link {
  cursor: pointer;
}
