.nav-con {
  background: #004598;
  height: 87px;
}

.nav-con ul {
  margin-top: 10px;
}

.logo-con {
  float: left;
  margin-top: 3px !important;
}

.logo-con-b2c {
  margin-top: 8px !important;
}

.logo-con img {
  height: 85px;
}

.logo-con-b2c img {
  height: 85px;
}

#side-bar-con {
  display: none;
  color: white;
  cursor: pointer;
}

.side-bar-color-part {
  z-index: 100;
  position: fixed;
  background-color: #004598;
  width: 280px;
  height: 100%;
  left: 0;
  top: 0;
}

.dark-side {
  position: fixed;
  z-index: 100;
  left: 280px;
  height: 100%;
}

.side-bar-full-screen {
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}

.menu-burger {
  position: relative;
  left: 10px;
  top: 20px;
  color: white;
  cursor: pointer;
  padding: 0 15px;
}

.side-bar-links-con a {
  display: block;
  margin: 20px 0;
  color: white;
}

.nav-links-con {
  margin-top: 16px;
  font-size: 19px;
  float: right;
}

.horizontal-menu {
  display: flex;
}

@media only screen and (max-width: 1000px) {
  #side-bar-con {
    display: initial;
    position: absolute;
    right: 13%;
    top: 20px;
  }

  .nav-links-con {
    display: none;
  }

  .horizontal-menu {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 400px) {
  .strech-full {
    text-align: center !important;
    padding-right: 0 !important;
  }

  .strech-full img {
    width: 90% !important;
  }

  .logo-con-b2c img {
    height: 65px;
  }
}

@media only screen and (max-width: 320px) {
  .logo-con {
    padding-right: 10px;
  }

  /* .logo-con img {
    width: 140px;
  } */
}

@media only screen and (max-width: 235px) {
  .logo-con img {
    width: 110px;
  }
}
