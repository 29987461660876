.close-border-top {
  border-top: 1px solid lightgray;
}

.fix-line-height {
  line-height: 2.5;
}

.field-width {
  width: 350px;
}

.remove-margin label {
  margin: 0;
}

.remove-margin legend {
  margin-right: 13px;
  font-weight: bold;
  color: #004598;
}

.prices-con {
  text-align: center;
  width: 300px;
  padding: 10px 0;
  border: 1px lightgray solid;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.vertical-sep {
  display: inline-block;
  background-color: lightgray;
  width: 1.5px;
  height: 25px;
  margin: 0 15px;
  vertical-align: -7px;
}

@media only screen and (max-width: 500px) {
  .field-width {
    width: 100%;
  }
}
