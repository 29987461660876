.full-screen-questions-wrapper {
  /* position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  background: white;
  margin-bottom: 50px;
}

.question-title {
  margin-top: 40px;
  margin-bottom: 60px;
  transform: translate(-50%);
  position: relative;
  left: 50%;
  padding: 0 15px !important;
}

.question-title h3,
.question-title h6 {
  color: #6e6e6e;
  text-align: center;
}

.radio-con {
  max-width: 800px;
  margin: 0 auto;
}

.question-input-con {
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
}

.img-selected {
  max-width: 300px;
  max-height: 200px;
}

.custom-nav {
  position: relative;
  height: 55px;
  padding: 10px 12%;
  border-bottom: 1px #d0d0d0 solid;
  z-index: 999;
}

.custom-nav-item {
  display: inline-block;
  margin-top: -2px;
}

.custom-nav-item img {
  width: 110px;
}

.custom-nav-item-abs-left {
  position: absolute;
  right: 12%;
  top: 17px;
}

.custom-nav-item-abs-left i {
  cursor: pointer;
}

.back-question-con {
  position: absolute;
  left: 1%;
  top: 97px;
}

.back-question-con i {
  font-size: 95px;
  color: #6e6e6e;
  cursor: pointer;
}

.visa-id-con {
  width: 280px;
  position: relative;
  right: 50%;
  transform: translate(50%);
  padding-bottom: 7px;
  padding-top: 15px;
  border: 1px solid #d0d0d0;
  border-top: none;
  background: white;
  margin-top: -5px;
  z-index: 1000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.visa-id-text {
  text-align: center;
  font-size: 13px;
}

.bottom-slider-con {
  position: fixed;
  bottom: 0;
  z-index: 501;
  width: 100%;
  padding: 23px 0;
  border-top: solid 1px #d0d0d0;
  background-color: white;
}

.txt-slider {
  margin-right: 13px;
  margin-left: 13px;
  vertical-align: 9px;
}

.slider-progress {
  width: 90%;
}

.slider-con-progress {
  width: 70%;
  margin: 0 auto;
}

.pass-icon-con {
  position: absolute;
  right: 530px;
  top: -13px;
}

.summary-con {
  display: inline-block;
  width: 28%;
  margin-top: 22px;
  margin-right: 20px;
}

.summary-cons {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 16px 0;
}

.from-to-con-icon {
  vertical-align: middle;
  margin: 0 8px;
}

.visa-opts-con {
  display: inline-block;
  width: 68%;
  text-align: right;
  vertical-align: top;
}

.visa-opt-card {
  width: 200px;
  min-height: 200px;
  border: 2px #c8c8c8 solid;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  color: #004598;
  display: inline-block;
  margin: 10px;
}

.font-25 {
  font-size: 25px;
}

.visa-opt-card-focused {
  border: #004598 solid;
}

.visa-opt-card:hover {
  border: #004598 solid;
}

@media only screen and (max-width: 992px) {
  .question-placement {
    /* overflow-y: scroll; */
    /* height: 100vh; */
    padding-bottom: 100px;
  }

  .visa-opts-con {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .pass-icon-con {
    position: static;
    text-align: center;
    margin-bottom: 20px;
  }

  .pass-icon-con i {
    font-size: 90px;
  }

  .summary-con {
    display: block;
    width: 320px;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 630px) {
  .visa-opts-con {
    width: 100%;
  }

  .check-mark-wrapper {
    left: 94%;
  }

  .back-question-con {
    position: absolute;
    left: 0%;
    top: 108px;
  }

  .back-question-con i {
    font-size: 52px;
  }
}
