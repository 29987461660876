.input-sm {
  width: 70px;
}

.width140 {
  width: 140px;
  margin: 0 auto;
}

.options-wrapper {
  width: 80%;
  margin: 10px auto;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
}
