:root {
  --green-color: #73c92b;
  --gray-dark: #a6a2a2;
  --gray-light: #d9d9d9;
  --blue-dark: #004598;
}

.questions-page-wrapper {
  border-radius: 5px !important;
  background: #9fbacd !important;
}

.group-info-header {
  height: 115px;
  background-color: var(--gray-light);
  color: black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 15px 0;
  margin-bottom: 25px;
}

.group-info-heading-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.group-info-header-subtitle {
  margin: 0;
  font-size: 20px;
}

.group-info-header-text {
  margin: 0px;
  font-size: 15px;
}

.side-borders {
  border-right: 3px solid white;
  border-left: 3px solid white;
}

.date-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
  font-size: 20px;
}

.user-info-table {
  margin-top: 80px;
}

.custom-checkbox {
  background-color: var(--gray-light);
  border: 1px solid var(--gray-dark);
  height: 20px;
  width: 20px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
  color: var(--green-color);
  font-weight: 900;
  font-size: 25px;
  position: relative;
  cursor: pointer;
}
.custom-checkbox:hover {
  background-color: white;
}

.check {
  height: min-content;
  width: min-content;
  position: absolute;
  right: -3px;
  bottom: -6px;
  padding: 0;
  margin: 0;
}

.push:active {
  box-shadow: none;
}

.push {
  cursor: pointer;
}

.link {
  color: var(--blue-dark);
  text-decoration: underline;
  cursor: pointer;
}

.link-break {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  word-break: break-all;
  word-wrap: break-word;
}
