@font-face {
  font-family: "Material Icons" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  src: url(./assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2") !important;
}

.material-icons {
  font-family: "Material Icons" !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 1 !important;
  letter-spacing: normal !important;
  text-transform: none !important;
  display: inline-block !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  direction: ltr !important;
  -webkit-font-feature-settings: "liga" !important;
  -webkit-font-smoothing: antialiased !important;
}

@font-face {
  src: url("./assets/fonts/FbCackau-Light.otf");
  font-family: heb-reg;
}

@font-face {
  src: url("./assets/fonts/FbCackau-Bold.otf");
  font-family: heb-bold;
}

.swal2-container {
  z-index: 99999 !important;
}

.default-font {
  font-family: Times New Roman;
}

.heb-bold {
  font-family: heb-bold;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  direction: rtl;
  text-align: right;
  font-family: heb-reg;
}

.rtl-layout {
  direction: rtl;
}

.ltr-layout {
  direction: ltr;
}

.b2c-background-color {
  background-color: #d1e6ff !important;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

/* overriding default behavior for material design rtl */
:not(.MuiInputLabel-shrink).MuiInputLabel-formControl {
  right: 25px;
  left: unset;
}

.custom-autocomplete .MuiInputLabel-formControl {
  right: initial;
}

.pb-70 {
  padding-bottom: 70px;
}

.width100 {
  width: 100%;
}

.primary-color {
  color: #004598;
}

.secundary-color {
  color: #d1e6ff;
}

.secundary-background {
  background-color: #d1e6ff !important;
}

.primary-background {
  background-color: #004598 !important;
}

.border-primary {
  border-color: #004598 !important;
}

.light-gray-background {
  background-color: #f5f5f5 !important;
}

.field-strech {
  width: 100%;
}

.width70 {
  width: 70%;
}

.border {
  border: 1px solid #0074d9;
  margin: 10px 0;
  padding-bottom: 10px;
  border-radius: 8px;
}

.flex-reg {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-con {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.admin-nav-con {
  background-color: #e3effd;
  height: 55px;
  width: 100%;
  color: #004598;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.err-msg {
  color: #f44336;
  font-size: 11.5px;
}

.admin-nav-con a {
  color: #004598;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
}

a.active-admin-link {
  padding-bottom: 0;
  border-bottom: 3px solid #73c92b;
}

.big-icon {
  font-size: 40px;
}

.fs-32 {
  font-size: 32px;
}

.max-width-500 {
  max-width: 500px;
}

.black-color {
  color: black;
}

.color-success {
  color: #73c92b !important;
}

.color-white {
  color: white !important;
}

.lightgray-brand {
  color: #6e6e6e;
}

.bg-light-blue {
  background: lightblue;
}

.general-shadow {
  box-shadow: 2px 2px 3px 0px rgba(50, 50, 50, 0.4);
  height: 100%;
}

.yellow-color {
  color: #fbae08;
}

.warn-color {
  color: #e56126;
}

.padding20 {
  padding: 20px;
}

.padding40 {
  padding: 40px;
}

.big-check {
  font-size: 140px;
}

.upload-file-btn {
  background-color: #e56126;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.attach-icon-align {
  vertical-align: -6px;
  margin: 0 5px;
}

.visa-created-card-con {
  width: 600px;
  margin: 0 auto;
}

.background-success {
  background-color: #73c92b !important;
}

.background-white {
  background: white;
}

.underline {
  text-decoration: underline;
}

.width33 {
  display: inline-block;
  vertical-align: top;
  width: 33%;
  padding: 8px;
}

.auto0 {
  margin: 0 auto;
}

.padding5 {
  padding: 5px;
}

.width50 {
  vertical-align: top;
  width: 45%;
  padding: 8px;
}

.width75 {
  width: 75%;
}

.select-default {
  width: 165px;
}

.card-width {
  width: 90%;
  max-width: 600px;
}

.custom-progress-bar {
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
}

.progress-bar-position {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity04 {
  opacity: 0.4;
}

.rounded-with-shadow {
  border-radius: 10px;
  box-shadow: 0px 10px 30px #13171f33 !important;
}

.pointer {
  cursor: pointer;
}

.margin10 {
  margin: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-b2c-nav {
  padding-top: 150px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-50 {
  padding-left: 50px;
}

.general-shadow {
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3) !important;
}

.hide {
  display: none;
}

.button-up-wrapper {
  position: fixed;
  left: 20px;
  bottom: 20px;
  background: #73c92b;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3) !important;
  cursor: pointer;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.button-up-wrapper i {
  font-size: 50px;
}

.btn-width160 {
  width: 160px;
}

.mt-minus100 {
  margin-top: -150px;
  z-index: 1;
}

.mt-minus10 {
  margin-top: -10px;
}

@media only screen and (max-width: 1000px) {
  .center-sm {
    text-align: center !important;
  }
  .no-padding-sm {
    padding: 0;
  }
  .mt-minus100 {
    margin-top: 100px;
    z-index: 1;
  }
}

@media only screen and (max-width: 770px) {
  .flex-break-770 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .visa-created-card-con {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .button-up-wrapper {
    background: #73c92b;
    width: 45px;
    height: 45px;
    left: 10px;
  }

  .button-up-wrapper i {
    font-size: 35px;
  }
}

@media only screen and (max-width: 350px) {
  .txt-next-line {
    width: 100px;
    word-break: break-word;
  }
  .position-new-visa-btn {
    margin-top: 20px;
    display: flex;
  }
}
