.visas-table-title-con {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

h2 {
  font-weight: 700;
  font-size: 40px;
  color: #004598;
}

h3 {
  font-weight: 700;
  font-size: 20px;
  color: #004598;
  text-align: center;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  color: #004598;
}

h4.white {
  color: white;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.visas-subtitle {
  font-weight: 400;
  font-size: 20px;
  color: #86A2C2;
}

.visas-popup-subtitle {
  text-align: center;
}

.visas-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  direction: rtl;
}

.agency-visas-extra-fields {
  background-color: #004598;
  color: white;
  padding: 20px 50px;
}

.visas-popup-main-body {
  padding: 20px 50px;
}

.popup-form-row {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
}

.popup-form-row + .popup-form-row {
  margin-top: 1rem;
}

.visas-button-con {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  align-items: flex-start;
}

.visas-tab-con {
  width: 100%;
  background-color: #F3F9FF;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 2rem;
  color: #9A9EA5;
  margin-bottom: 5px;
  font-size: 22px;
}

.visas-tab {
  padding: 5px 0;
  cursor: pointer;
  box-sizing: content-box;
}

.visas-tab-active {
  color: black;
  border-bottom: 5px solid #73C92B;
}

.visas-table-con {
  width: 100%;
  background-color: #F3F9FF;
  padding: 0.5rem 2rem 1rem 2rem;
  margin-bottom: 3rem;
}

.visas-search-box {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.visas-search-box-top {
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
}

.visas-search-box-chips {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 8px;
}

.light-button {
  color: #004598 !important;
  border: 1px solid #004598 !important;
  background-color: #E3EFFD !important;
}

.green-button {
  color: white !important;
  background-color: #73C92B !important;
}

#base-visa-type-button {
  color: #004598;
  background-color: #E3EFFD;
  font-weight: 600;
  border-radius: 12px;
  font-size: 23px;
  min-width: 8ch;
}

#active-visa-type-button {
  background-color: #004598;
  color: white;
  font-weight: 600;
  border-radius: 12px;
  font-size: 23px;
  min-width: 8ch;
}

.MuiChip-root {
  background-color: #DEEFCF !important;
  padding: 10px !important;
}