.new-from-to-btn {
  position: absolute;
  left: 50px;
  top: 50px;
}

.width40 {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  padding: 8px;
}

.arrows-separators {
  width: 5%;
  display: inline-block;
  position: relative;
}

.min-height-400 {
  min-height: 400px;
}

.actual-arrows-place {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.actual-arrows-place i {
  font-size: 40px;
}

.included-fields {
  background: #28a745 !important;
  color: white !important;
}

.excluded-fields {
  background: #ffc107 !important;
  color: white !important;
}

.save-fields-wrapper {
  border: 1px solid #0074d9;
  border-radius: 30px;
  padding: 5px 0;
  cursor: pointer;
}

.toggle-file-visa {
  position: absolute;
  right: 0;
}

.delete-con-visa-opt {
  position: absolute;
  left: 0;
}
