#bit-button-pay {
  background-color: #008897;
  color: white;
  font-weight: bold;
  width: 250px;
  border-radius: 1rem;
}

#credit-button-pay {
  background-color: #003e88;
  color: white;
  font-weight: bold;
  width: 250px;
  border-radius: 1rem;
}

#bit-button-pay:hover, #credit-button-pay:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.payment-logo-container {
  text-align: center;
}

.payment-logo {
  margin: 30px 0 30px 0;
  max-width: 350px;
}