.position-new-visa-btn {
  position: absolute;
  left: 0;
  top: 0;
}

.client-fills-fields {
  width: 31%;
  display: inline-block;
  margin-right: 2%;
}

@media only screen and (max-width: 621px) {
  .sm-90-per {
    width: 90% !important;
    display: block;
  }

  .remove-space-sm {
    width: 100%;
    display: block;
    margin-left: 0;
    margin-top: 20px;
  }

  .mt-sm-20 {
    margin-top: 20px;
  }
}

.tool_box {
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 20px;
}

.textfield-margin-bottom > div {
  margin-bottom: 15px;
}

.textfield-margin-bottom {
  display: flex;
  flex-wrap: wrap;
}

.search__box {
  justify-content: left;
  margin-right: 0px;
  width: 300px;
  margin-top: 10px;
}

.date__picker {
  justify-content: right;
  margin-top: 12px;
}

.date__picker .end_picker {
  margin-left: 20px;
  margin-right: 0px;
}

.tool_box .search_btn {
  height: 50px;
  width: 150px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.tool_box .status_selected {
  margin-right: 20px;
  width: 200px;
}

.tool_box .search_btn:hover {
  background-color: rgb(7, 72, 156);
  color: white;
}

.tool_box .clr_btn {
  height: 55px;
  margin-top: 10px;
}

.tool_box .clr_btn:hover {
  background-color: rgb(187, 66, 66);
  color: white;
}

.popup__visa .popup_title {
  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.modal_popup {
  width: 35vw;
  height: 55vh;
  /* display: flex; */
}

.fields_agent {
  display: flex;
}

:not(.MuiInputLabel-shrink).MuiInputLabel-formControl {
  left: 0;
}

@media only screen and (max-width: 350px) {
  .position-new-visa-btn {
    margin-top: 100px;
    display: block;
  }
}
