.banner-text-con {
  text-align: center;
  height: 500px;
  position: relative;
  color: white;
}
.text-in-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4.5rem;
  text-shadow: 2px 2px 4px #000000;
}
.our-story-con {
  margin-top: 60px;
  width: 80%;
  max-width: 1290px;
  margin: 60px auto;
}
.our-story-img {
  margin-top: 10px;
  width: 25%;
  display: inline-block;
}

.our-story-img img {
  width: 100%;
}

.our-story-text {
  width: 65%;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  margin-right: 10px;
}
.our-stats-con {
  background-color: #004598;
  padding: 20px 0;
  position: relative;
}

.our-stats-text {
  margin-left: 20px;
}

.our-stats-info {
  width: 60%;
  display: inline-block;
  color: white;
  text-align: right;
}

.stats-bar-progress {
  margin-top: 10px;
  width: 100%;
}

.border-progress {
  height: 10px;
  border-radius: 5px;
  background-color: #e6e6e6;
}

.bar-progress {
  width: 70%;
  height: 10px;
  border-radius: 5px;
  background-color: #73c92b;
}

.our-stats-img {
  width: 40%;
  display: inline-block;
}

.our-stats-img img {
  margin: 0 auto;
  vertical-align: -55px;
}

.testimonial-con {
  width: 50%;
  margin: 60px auto;
}

.testimonial-img {
  width: 40%;
  margin: 0 auto;
  border-radius: 50%;
  border: 5px solid #73c92b;
  box-shadow: 2px 2px 5px 0px rgb(65, 65, 65);
}

.testimonial-comment {
  position: relative;
  text-align: center !important;
  margin-top: 40px;
  border-top: solid 2px #004598;
  border-bottom: solid 2px #004598;
}

.testimonial-comment:after {
  position: absolute;
  content: "”";
  color: #004598;
  font-size: 150px;
  line-height: 0;
  bottom: -36px;
  left: 20px;
}

.our-story-hr {
  border-top: 1px solid white;
}

@media only screen and (max-width: 1000px) {
  .our-story-img {
    display: block;
    width: 320px;
    margin: 50px auto;
  }

  .our-story-text {
    display: block;
    margin: 0 auto 50px auto;
    width: 90%;
    text-align: center;
  }

  .our-stats-text {
    margin-left: 0;
  }

  .our-story-text h1,
  .our-story-text h4,
  .our-story-text h5,
  .our-stats-text h1,
  .our-stats-text h4,
  .our-stats-text h6 {
    text-align: center;
  }

  .our-stats-info {
    display: block;
    width: 90%;
    margin: 560px auto 50px auto;
    text-align: center;
  }

  .our-stats-img {
    position: absolute;
    top: 50px;
    left: 50%;
    width: unset;
    transform: translate(-50%, 0);
  }

  .our-story-hr {
    margin: 50px 0;
  }
}

@media only screen and (max-width: 500px) {
  .our-story-img,
  .our-stats-img {
    width: 80%;
  }

  .our-stats-img img {
    width: 100%;
  }

  .testimonial-comment:after {
    font-size: 60px;
    bottom: -14px;
  }
}

@media only screen and (max-width: 400px) {
  .our-stats-info {
    margin-top: 500px;
  }

  .our-stats-img {
    top: 90px;
  }
}
