.fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  pointer-events: none; /* Allows interactions with the image below */
}

.b2c-banner-text {
  position: absolute;
  left: 50%;
  top: 35%;
  translate: -50% -50%;
  min-width: 365px;
  padding-top: 120px;
}

.b2c-home-title {
  font-size: 48px;
  text-shadow: 10px 10px 16px #D1E6FF, -10px -10px 16px #D1E6FF;
}

.b2c-home-subtitle-container {
  max-width: 480px;
}

.b2c-home-subtitle {
  text-shadow: 10px 10px 16px #D1E6FF, -10px -10px 16px #D1E6FF;
  font-size: 25px;
}

.b2c-origin-dest-wrapper {
  width: 100%;
  margin-top: 50px;
  padding: 2rem 4% 2rem 4%;
  height: 120px;
  border-radius: 12px;
  border: 2px solid rgba(156, 194, 239, 0.56);
  box-shadow: 4px 4px 22px 0px rgba(156, 194, 239, 0.56);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
}

.b2c-selection-tag {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.b2c-origin-field{
  flex-grow: 10;
}

.b2c-icon-flights-container {
  padding: 4px 2px;
  border-radius: 5px;
}

.b2c-world-icon {
  margin: 0 0 4px 5px;
}

.b2c-origin-dest {
  display: flex;
  align-items: flex-end;
  width: 32%;
}

.b2c-vertical-7 {
  vertical-align: -7px;
  width: 10%;
  margin-right: 10px;
}

.b2c-country-flag {
  width: 75px;
  height: 37.5px;
}

.b2c-window-con {
  height: 330px;
  width: 280px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 4px 4px 22px 0px #D1E6FF;
}

.b2c-cardImage {
  height: 65%;
  object-fit: cover;
}

.b2c-popularCardInformationContainer > div {
  align-items: flex-start;
}

.b2c-popularCardContentContainer {
  box-sizing: border-box;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.b2c-popularCardInformationContainer {
  justify-content: space-between;
  align-items: center;
}

.b2c-popularCardInformationContainer > div > h5 {
  display: inline-block;
  vertical-align: -14px;
}

.b2c-popularCardInformationContainer > div > p {
  margin: -5px 0;
}

.b2c-weight600 {
  font-weight: 600;
}

.b2c-step-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
    align-items: center;
}

.b2c-step-card {
    height: 320px;
    width: 270px;
    position: absolute;
    top: 15px;
    left: 37px;
    z-index: 2;
}

.b2c-step-image {
    width: 350px;
    height: 350px;
}

.b2c-step-content {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    text-align: right;
}

.b2c-step-background {
    top: 0;
    left: 0;
}

.b2c-step-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }
  
.b2c-step-destination-icon {
    top: 120px;
    left: 330px;
}

.b2c-step-business-icon {
    top: 90px;
    left: 330px;
}

.b2c-step-umbrella-icon {
    top: 155px;
    left: 330px;
}

.b2c-step-travel-icon {
    top: 135px;
    left: 315px;
}

.b2c-step-icon {
    margin-top: -5px;
}

.b2c-step-path-one {
    top: 85px;
    left: 285px;
}

.b2c-step-path-two {
    top: 100px;
    left: 260px;
}

.b2c-step-path-three {
    top: 45px;
    left: -15px;
}

.b2c-step-illustration {
  position: absolute;
  top: 20px;
  left: 20px;
}

.b2c-step-plane-image {
  position: absolute;
  top: -35px;
  left: -70px;
}

@media only screen and (max-width: 1200px) {
  .b2c-window-con:nth-child(3) {
    display: none;
  }

  .b2c-popularCardContainer {
    justify-content: center;
    gap: 53px;
  }
}

@media only screen and (max-width: 1000px) {
  .b2c-align-banner-height {
    margin-top: 85px;
  }
}

@media only screen and (max-width: 991px) {
  .b2c-home-banner-img {
    height: 300px;
    object-fit: cover;
    object-position: center;
  }

  .b2c-home-title {
    font-size: 54px;
  }

  .b2c-home-subtitle-container {
    max-width: 370px;
  }

  .b2c-home-subtitle {
    font-size: 22px;
  }

  .b2c-origin-dest-wrapper {
    flex-direction: column;
    align-items: flex-start;
    height: 240px;
  }

  .b2c-origin-dest {
    width: 100%;
  }

  .b2c-world-icon {
    margin: 0 4px 4px 7px;
  }

  .b2c-destination-arrow {
    display: none;
  }

  .b2c-step-container {
    gap: 5rem;
  }

  .b2c-step-content {
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .b2c-step-container:nth-child(2) > .b2c-step-image {
    order: 1;
  }

  .b2c-step-container:nth-child(2) > .b2c-step-content {
    order: 2;
  }
}

@media only screen and (max-width: 480px) {
  .b2c-home-title {
    font-size: 48px;
  }
}